import React from 'react'
import Button from '../components/common/Button'
import { Form } from 'react-final-form'
import Error from '../components/common/Error'
import Input from '../components/Input'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import FormContainer from '../components/Login/FormContainer'
import forgotPasswordValidator from '../validation/forgotPasswordValidator'
import { firebaseToFinalFormErrorTransformer } from '../components/Login/Login'
import { useUserContext } from '../contexts/User/userContext'
import { NoIndex } from '../components/NoIndex'
import { useLocalizedContent } from '../utils/localization/useLocalizedContent'


const ForgotPasswordForm = ({ translations }) => {
  const { sendPasswordResetEmail } = useUserContext()

  const onSubmit = async data => {
    try {
      await sendPasswordResetEmail(data.email.toLowerCase())
      await navigate('/login')
    } catch (e) {
      return firebaseToFinalFormErrorTransformer(e, translations)
    }
  }

  return (
    <>
      <NoIndex />
      <Form onSubmit={onSubmit} validate={forgotPasswordValidator(translations)}>
        {props => {
          const {
            handleSubmit,
            submitting,
            submitErrors,
            submitError,
            hasValidationErrors,
            dirtySinceLastSubmit
          } = props

          return (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col text-left">
                <div className="mt-6">
                  <Input
                    type="email"
                    label={translations[translationsKeys.emailLabel] || "Email address"}
                    name="email"
                    placeholder={translations[translationsKeys.emailPlaceholder] || "Please enter your email"}
                    hideError={dirtySinceLastSubmit && !hasValidationErrors}
                  />
                </div>
                <div className="sticky w-full">
                  {!dirtySinceLastSubmit &&
                    ((submitErrors && submitErrors.length > 0) ||
                      submitError) && (
                      <div className="mt-4 mb-2">
                        <Error
                          messages={submitError ? [submitError] : submitErrors}
                        />
                      </div>
                    )}
                </div>
                <div className="mx-auto mt-6 newstyle">
                  <Button
                    id="forgot-button"
                    isBlock
                    disabled={submitting || hasValidationErrors}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {translations[translationsKeys.resetPassword] || "Reset password"}
                  </Button>
                </div>
              </div>
            </form>
          )
        }}
      </Form>
    </>
  )
}

const ForgotPassword = () => {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  return (
    <div className="flex flex-col items-center justify-center w-full px-4 pt-2 pb-8 mb-4 md:px-8 md:pt-6 ">
      <FormContainer
        title={translations[translationsKeys.title] || "Forgot Password"}
        subTitle={translations[translationsKeys.subtitle] || "Enter your email and we'll send you a link to reset your password"}
        Component={() => <ForgotPasswordForm translations={translations} />}
      />
    </div>
  )
}

const query = graphql`
  query ForgotPasswordQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "forgot-password"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`
const translationsKeys = {
  title: 'title',
  subtitle: 'subtitle',
  emailLabel: 'emailLabel',
  emailPlaceholder: 'emailPlaceholder',
  resetPassword: 'resetPassword',
  emailRequiredError: 'emailRequiredError',
  emailError: 'emailError',

}

export default ForgotPassword
