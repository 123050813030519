import { emailValidators } from './loginValidator'
import validator from './validator'

const errorMessages = (translations) => {
  return {
    email: emailValidators(translations)
  }
}

const forgotPasswordValidator = (translations = {}) => (values) => {
  return validator(errorMessages(translations), values)
}

export default forgotPasswordValidator
